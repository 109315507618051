import styled from "styled-components"
import React from "react"
import Container from "../container"
import "../scss/block/defaultBlogCta.scss"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { linkResolver } from "../../utils/linkResolver"
import prismicHtmlSerializer from "../../gatsby/htmlSerializer"
import { RichText } from "prismic-reactjs"
const AccordionStyle = styled.div`
  section {
    padding: 0px !important;
  }
  .accordion__button {
    padding: 20px 0px;
    font-size: 27px;
    font-weight: bold;
    cursor: pointer;
    &:focus {
      border: 0px;
      outline: none;
    }
  }
  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: -3px;
    position: relative;
  }
  .accordion__button[aria-expanded="true"]:before,
  .accordion__button[aria-selected="true"]:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ul{
    li{
      a{
        text-decoration: underline;
      }
    }
  }
`

export const AccordionSlice = ({ slice }) => {

  console.log(slice)
 

  return (
    <AccordionStyle>
    <Container className="accordion-slice-container">
    <Accordion allowMultipleExpanded allowZeroExpanded>
    {slice.items.map((item, index) => (
      item.accordion_title.text && (
        <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{item.accordion_title.text}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
        {item.accordion_richtext.raw && (
          <RichText
            render={item.accordion_richtext.raw}
            linkResolver={linkResolver}
            htmlSerializer={prismicHtmlSerializer}
          />
        )}

        </AccordionItemPanel>
      </AccordionItem>

  )
      
    ))}
  </Accordion>
  </Container>
    </AccordionStyle>
  )
}

export default AccordionSlice
